import styled from 'styled-components';

import { Layout } from "antd";

import {CloseCircleOutlined } from "@ant-design/icons";

const { Sider } = Layout;

export const DesktopSider = styled(Sider) `
    display : none;
    z-index : 999 !important;
    height : 100% !important;
    width : 100% !important;
    position : fixed !important;
    overflow : none !important;
    @media only screen and (min-width: 600px) {
        display : block;
      }
`;

export const MobileSider = styled(Sider) `
    display : none;
    z-index : 999 !important;
    height : 100% !important;
    width : 100% !important;
    position : fixed !important;
    overflow : none !important;
    @media only screen and (max-width: 600px) {
        display : ${({isMobileNavigationActive})=>(isMobileNavigationActive ? "block" : "none")};
      }
`;

export const CloseIconButton = styled(CloseCircleOutlined) `
    font-size 25px;
    &:hover {
        opacity: 0.3;
      }
`;
export const CloseIconWrapper = styled.div `
    height : 54px;
    width : 50px;
`;

export const IsClosableContainer = styled.div `
  display : flex;
  justify-content : flex-end;
`;


