import React from "react";
import {
  CardContainer,
  CardHeader,
  CardHeaderTitle,
  CardHeaderSubTitle,
  CardBody,
  CardFooter,
  LeftFooterActionContainer,
  MiddleFooterActionContainer,
  RightFooterActionContainer,
} from "./style";

const Card = ({
  title,
  subTitle,
  leftFooterAction,
  rightFooterAction,
  middleFooterAction,
  children,
}) => {
  return (
    <CardContainer>
      <CardHeader>
        <CardHeaderTitle>{title}</CardHeaderTitle>
        <CardHeaderSubTitle>{subTitle}</CardHeaderSubTitle>
      </CardHeader>
      <CardBody>{children}</CardBody>
      <CardFooter>
        <LeftFooterActionContainer>
          {leftFooterAction}
        </LeftFooterActionContainer>
        <MiddleFooterActionContainer>
          {middleFooterAction}
        </MiddleFooterActionContainer>
        <RightFooterActionContainer>
          {rightFooterAction}
        </RightFooterActionContainer>
      </CardFooter>
    </CardContainer>
  );
};

Card.defaultProps = {
  title: "",
  subTitle: "",
  leftFooterAction: () => {},
  middleFooterAction: () => {},
  rightFooterAction: () => {},
};

export default Card;
