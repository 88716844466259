import React, { useState, useRef, useEffect } from "react";

import '../../App.css';

import styled from "styled-components";

import moment from "moment";

import { Promise } from "bluebird";

import Logo from "../../assets/images/cotrugli-logo-180px.png";

import { InboxOutlined, MenuOutlined } from "@ant-design/icons";

import AppContext from "../../utils/context/app-context";

import { useMediaQuery } from "../../utils/hooks";

import axios from 'axios';

import { generateFileHash, generateFileUrl } from "../../utils/FileHelper";

import {
  tolar,
  COTRUGLIContract,
} from "../../utils/Web3Helper";

import PdfViewer from "../../components/pdf-components/index";

import {Upload, notification } from "antd";

import abi from "ethereumjs-abi";

const Content = styled.div ``;

const Footer = styled.div ``;

const { Dragger } = Upload;

const HeadlineToDisplay = styled.div`
  text-align : center;
  font-size : 20px;
  padding 45px;
`;

const PoweredByContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 24px auto 1rem;
  background-color: #fff;
  width: 205px;
  font-size: 13px;
  align-items: center;
  padding: 12px;
  border: 1px solid #ebedf1;
  border-radius: 4px;
  font-weight: 500;
  line-height: 14px;
  border-radius: 4px;
  border: 1px solid grey;
  box-shadow: grey 0px 4px 16px 0px;
`;

const SpanContainer = styled.span`
  color: rgba(20, 33, 61, 0.5);
`;

const PoweredByImage = styled.img`
  height: 23px;
`;

const COTRUGLILogo = styled.img`
 z-index : 999;
 height : 60px;
 width 200px;
 position : absolute;
`;

const ValidateCertificatePage = () => {
  const [loading, setLoading] = useState(false);

  const [fileList, _setFileList] = useState([]);

  const isMobile = useMediaQuery("(max-width: 600px)");

  const [modalsData, _setModalsData] = useState([]);

  const modalsDataRef = React.useRef(modalsData);

  const fileListRef = React.useRef(fileList);

  const setModalsData = (data) => {
    modalsDataRef.current = data;
    _setModalsData(data);
  };

  const setFileList = (data) => {
    fileListRef.current = data;
    _setFileList(data);
  };

  const setIsModalVisible = (key, visible) => {
    setModalsData([
      ...modalsDataRef.current.map((modalData) =>
        modalData.uid === key
          ? { ...modalData, isModalVisible: visible }
          : modalData
      ),
    ]);
  };

  const uploadProps = {
    multiple: true,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);

      setFileList(newFileList);
    },
    beforeUpload: async (file, fs) => {
      setFileList([...fileList, ...fs]);
      setModalsData(
        await Promise.map(fs, async (file) => ({
          fileUrl: await generateFileUrl(file),
          title: file.name,
          isModalVisible: true,
          setIsModalVisible,
          uid: file.uid,
        }))
      );
      const { fileHash } = await generateFileHash(file);

      let nonce = await tolar.getNonce(
        "54fa4807f2bd449c21d8459acc1536df8d66e9daf509f4340c"
      );
      const verifyDiploma = abi.simpleEncode(
        "verifyDiploma(string):(string,uint256,string)",
        fileHash.toString()
      );
      const verifyDiplomaHex = verifyDiploma.toString("hex");

      let tx = await tolar.tryCallTransaction(
        "54fa4807f2bd449c21d8459acc1536df8d66e9daf509f4340c",
        COTRUGLIContract,
        0,
        1000000,
        0,
        verifyDiplomaHex,
        nonce
      );

      console.log(tx);

      const {data} = await axios.get(`https://app.cotrugli.tech/api-v2/certificates`);
      const certificates = data.filter(x => x.fileHash === fileHash);

      if (tx.output === "RevertInstruction") {
        notification.error({
          message: "Error!",
          description: `File ${file.name} is invalid!`,
          onClick: () => {
            console.log("Notification Clicked!");
          },
        });
      } else {
        notification.success({
          duration: 4000,
          message: "Success!",
          className:'hash',
          description: `${file.name} is validated at : ${certificates && certificates[0] && moment(certificates[0].blockchainUploadDate).format('LLLL')} by COTRUGLI Business school!
                        Name : ${file.name.replace(".pdf","")}, Digital Signature : ${fileHash}, click on this Transaction Hash to view the transaction : ${certificates && certificates[0] &&  certificates[0].description}!`,
          onClick: () => {
            console.log("notification clicked!")
            window.open(`https://explorer.tolar.io/transaction/${certificates && certificates[0] && certificates[0].description}`)
          },
        });
      }
    },
    fileList,
  };

  return (
    <>
      {modalsData.map(
        ({ fileUrl, title, isModalVisible, setIsModalVisible, uid }) => (
          <PdfViewer
            title={title}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            fileUrl={fileUrl}
            key={uid}
            uid={uid}
            isConfirmable={false}
          />
        )
      )}
      <AppContext.Consumer>
        {({ isMobileNavigationActive, setIsMobileNavigationActive }) => (
            <div  className="container">
            {isMobile && (
                <MenuOutlined
                  onClick={() => setIsMobileNavigationActive(true)}
                  style={{
                    color: "white",
                    marginLeft: "20px",
                    fontSize: "25px",
                  }}
                />
              )}
              
            <HeadlineToDisplay>
              <h1 style ={{display : "flex", flexDirection : "column" , position : "relative", paddingLeft: "50px", fontSize: "30px"}}>
                Add your document and check is it verified on the blockchain!
              </h1>
            </HeadlineToDisplay>
            
            
            
            <Content
              style={{
                margin: isMobile ? "0px" : "0px",
                "24px": "16px",
                overflow: "initial",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <div
                className="site-layout-background"
                style={{
                  padding: 48,
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  maxWidth: "1000px",
                }}
              >
                <Dragger {...uploadProps} style={{maxHeight: '200px',  borderRadius: "4px", border: "1px solid black",boxShadow: "black 0px 4px 16px 0px" }}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint" style={{padding : "12px"}}>
                    Support for a single or bulk upload. Strictly prohibit from
                    uploading company data or other band files
                  </p>
                </Dragger>
                <br />
                <PoweredByContainer>
                  <SpanContainer>Powered by</SpanContainer>
                  <a href="https://cotrugli.org/" target="_blank">
                    <PoweredByImage src={Logo} alt="Cotrugli" />
                  </a>
                </PoweredByContainer>
              </div>
            </Content>
            <Footer style={{ textAlign: "center", paddingTop : "135px" , fontSize : "15px"}}>
              COTRUGLI ©2020 Created by COTRUGLI Business School
            </Footer>
            </div>
        )}
      </AppContext.Consumer>
      
    </>
  );
};

export default ValidateCertificatePage;
