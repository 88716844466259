import React, { useState, useEffect } from "react";

import axios from "axios";

import abi from "ethereumjs-abi";

import styled from "styled-components";

import image from "../../assets/images/cotrugli-logo-bijeli.png";

import { useHistory } from "react-router-dom";

import { Layout, Input, Form, Button, notification, Select } from "antd";

import { MenuOutlined, AppstoreAddOutlined } from "@ant-design/icons";

import AppContext from "../../utils/context/app-context";

import { useMediaQuery } from "../../utils/hooks";
import DetailsCard from "./components/card-details";

import { getBase64FromUrl } from "../../utils/FileHelper";

import {debounce} from 'lodash'

const { Header, Content, Footer } = Layout;

const { Option } = Select;

const { Search } = Input;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  padding-right: 164px;
`;

const HeadlineToDisplay = styled.div`
  text-align : center;
  font-size : 20px;
  padding 30px;
`;

const ButtonWrapper = styled.div`
  padding-left: 77px;
`;

const COTRUGLILogo = styled.img`
 z-index : 999;
 height : 60px;
 width 200px;
 position : absolute;
`;

const COTRUGLILogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 2px;
`;

const ItemsListPage = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [certificateDetails, setCertificateDetails] = useState([]);
  const history = useHistory();
  const [filteredCertificateDetails, setFilteredCertificateDetails] = useState([]);

  useEffect(() => {
    axios
      .get("https://app.cotrugli.tech/api-v2/certificates")
      .then(function (response) {
        // handle success
        setCertificateDetails(response.data);
        setFilteredCertificateDetails(response.data)
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }, []);

  const onFilter = (e) => {
    const value = e.target.value;
    if (!value){
      setFilteredCertificateDetails(certificateDetails);
      return;
    }
    setFilteredCertificateDetails(certificateDetails.filter(({fileName}) => fileName.toLowerCase().startsWith(value.toLowerCase())));
  };

  const debouncedFilter = debounce(onFilter, 250, { 'maxWait': 1000 });

  return (
    <AppContext.Consumer>
      {({ isMobileNavigationActive, setIsMobileNavigationActive }) => (
        <Layout
          style={{
            marginLeft: isMobile ? "0px" : "200px",
            marginRight: isMobile ? 0 : 20,
            minHeight: isMobile ? "100%" : "98vh",
          }}
        >
          <COTRUGLILogoContainer>
            <COTRUGLILogo src={image} alt="Cotrugli" />
          </COTRUGLILogoContainer>
          <Header
            className="site-layout-background"
            style={{ padding: 0, marginLeft: isMobile ? "0px" : "100px" }}
          >
            {isMobile && (
              <MenuOutlined
                onClick={() => setIsMobileNavigationActive(true)}
                style={{ color: "white", marginLeft: "20px", fontSize: "25px" }}
              />
            )}
          </Header>
          <Content style={{ margin: "24px 16px 0", overflow: "initial", flex: 1}}>
            <HeadlineToDisplay>
              <h1>Items list</h1>
            </HeadlineToDisplay>
            <ButtonWrapper>
              <InputContainer>
                <Button
                  size="large"
                  type="primary"
                  loading={loading}
                  style={{
                    width: "250px",
                  }}
                  icon={<AppstoreAddOutlined />}
                  htmlType="submit"
                  className="login-form-button"
                  onClick={() => {
                    history.push("/certificates/start");
                  }}
                >
                  Add new document
                </Button>
                <Search
                  placeholder="input search text"
                  allowClear
                  enterButton="Search"
                  size="large"
                  onChange={debouncedFilter}
                  style={{ width: "507px" }}
                />
              </InputContainer>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {filteredCertificateDetails.map(
                  (
                    {
                      id,
                      fileName,
                      fileHash,
                      fileSize,
                      fileType,
                      sourceUrl,
                      createdAt,
                      status
                    },
                    index
                  ) => (
                    <DetailsCard
                      fileName={fileName}
                      key={id}
                      description={"Added : " + createdAt + " by Admin"}
                      fileType={fileType}
                      fileSize={fileSize + "KB"}
                      digitalSignature={fileHash}
                      sourceUrl={"https://app.cotrugli.tech/api-v2/certificates/"+sourceUrl}
                      status={status}
                      id={id}
                    />
                  )
                )}
              </div>
            </ButtonWrapper>
          </Content>
          <Footer style={{ textAlign: "center" }}>
            COTRUGLI ©2020 Created by COTRUGLI Business School
          </Footer>
        </Layout>
      )}
    </AppContext.Consumer>
  );
};

export default ItemsListPage;
