import { React, useState, useEffect } from "react";

import Card from "../../../../components/card";

import { Button } from "antd";

import {
  AppstoreAddOutlined,
  LockOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";

import {
  BoldText,
  Column,
  Line,
  Text,
  FlexRow,
  CardDetailsContainer,
} from "./style";

import abi from "ethereumjs-abi";

import { notification } from "antd";

import {
  tolar,
  COTRUGLIContract,
  web3,
} from "../../../../utils/Web3Helper";

import {
  generateFilesHashes,
  getBase64FromUrl,
} from "../../../../utils/FileHelper";

import PdfViewer from "../../../../components/pdf-components";

import axios from 'axios';

import moment from 'moment';

const DetailsCard = ({
  fileName,
  description,
  fileSize,
  fileType,
  digitalSignature,
  sourceUrl,
  status: initialStatus,
  id,
}) => {
  const [isModalVisible, _setIsModalVisible] = useState(false);
  const [sourceUri, setSourceUri] = useState(sourceUrl);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(initialStatus)

  const setIsModalVisible = (uid, opened) => {
    _setIsModalVisible(opened);
  };

  const onOpenPdf = async () => {
    _setIsModalVisible(true);
    const temp = await getBase64FromUrl(sourceUrl);
    setSourceUri(temp);
  };

  const onAddToBlockchain = async () => {
    
  };
  return (
    <>
      <PdfViewer
        title={fileName}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        fileUrl={sourceUri}
        key={digitalSignature}
        uid={digitalSignature}
        isConfirmable={false}
      />
      <CardDetailsContainer>
        <Card
          title={fileName}
          subTitle={description}
          leftFooterAction={
            <Button
              size="middle"
              onClick={onAddToBlockchain}
              loading={loading}
              disabled={status === "ADDED_TO_BLOCKCHAIN"}
              style={{
                width: "100%",
                ...(status === "ADDED_TO_BLOCKCHAIN"
                  ? {
                      backgroundColor: "green",
                      color: "black",
                    }
                  : {}),
              }}
              icon={<AppstoreAddOutlined />}
              className="login-form-button"
            >
              {status === "ADDED_TO_BLOCKCHAIN" ? 'Registered' : 'Add to blockchain!'}
            </Button>
          }
          rightFooterAction={
            <Button
              size="middle"
              style={{
                width: "100%",
              }}
              icon={<AppstoreAddOutlined />}
              htmlType="submit"
              className="login-form-button"
              onClick={onOpenPdf}
            >
              View
            </Button>
          }
        >
          <FlexRow>
            <AppstoreAddOutlined />
            <Column>
              <Text>File size</Text>
              <BoldText>{fileSize}</BoldText>
            </Column>
            <FilePdfOutlined />
            <Column>
              <Text>File Type</Text>
              <BoldText>{fileType}</BoldText>
            </Column>
          </FlexRow>
          <Line></Line>
          <FlexRow>
            <LockOutlined />
            <Column>
              <Text>Document Digital Signature</Text>
              <BoldText>{digitalSignature}</BoldText>
            </Column>
          </FlexRow>
        </Card>
      </CardDetailsContainer>
    </>
  );
};

DetailsCard.defaultProps = {
  fileName: "This is the file name.pdf",
  description: "Created on 07/20/2121 by Admin",
  fileSize: "5MB",
  fileType: "Adobe PDF Document",
  digitalSignature: "5dsa4d5sa1d3sd1w584f1fdgfdhfd",
  status: "CREATED"
};

export default DetailsCard;
