import React, { useState } from "react";

import { Menu } from "antd";

import {
  DesktopSider,
  MobileSider,
  CloseIconButton,
  CloseIconWrapper,
  IsClosableContainer,
} from "./style";

import { Link } from "react-router-dom";

import COTRUGLIBijeli from '../../assets/images/cotrugli-logo-bijeli.png'

import {
  AppstoreOutlined,
  FileAddOutlined,
  SafetyCertificateOutlined,
} from "@ant-design/icons";

import AppContext from "../../utils/context/app-context";

/*<Menu.Item
    key="1"
    onClick={() => {
      isMobile && hideNavigation();
    }}
    icon={<AppstoreOutlined />}
  >
    <Link to="/list/items">Documents list</Link>
  </Menu.Item>
   
    <Menu.Item
      key="2"
      onClick={() => {
        isMobile && hideNavigation();
      }}
      icon={<FileAddOutlined />}
    >
      <Link to="/certificates/start">Add new document</Link>
    </Menu.Item>*/



const ResponsiveMenu = ({
  defaultSelectedKey,
  isMobile,
  hideNavigation,
  isClosable,
}) => (
  <Menu
    theme="dark"
    mode="inline"
    defaultSelectedKeys={[defaultSelectedKey ? defaultSelectedKey[1] : 1]}
  >{isClosable && (
    <IsClosableContainer onClick={hideNavigation}>
      <CloseIconWrapper>
        {" "}
        <CloseIconButton />{" "}
      </CloseIconWrapper>
    </IsClosableContainer>
  )}
    <Menu.Item
      key="3"
      onClick={() => {
        isMobile && hideNavigation();
      }}
      icon={<SafetyCertificateOutlined />}
    >
      <Link to="/certificates/validate">
        Verify document
      </Link>
    </Menu.Item>
  </Menu>
);

ResponsiveMenu.defaultProps = { isMobile: false, hideNavigation: () => {} };

const ResponsiveNavigation = ({ defaultSelectedKey }) => {

  return (
    <AppContext.Consumer>
      {({ isMobileNavigationActive, setIsMobileNavigationActive }) => (
        <>
          <DesktopSider
            style={{
              overflow: "auto",
              height: "100vh",
              position: "fixed",
              left: 0,
            }}
            width="280px"
          >
            <img className="logo" src ={COTRUGLIBijeli}/>
            <ResponsiveMenu defaultSelectedKey={defaultSelectedKey} />
          </DesktopSider>

          <MobileSider
            isMobileNavigationActive={isMobileNavigationActive}
            style={{
              overflow: "auto",
              height: "100vh",
              position: "fixed",
              left: 0,
            }}
            width="100%"
          >
            <div className="logo" />
            <ResponsiveMenu
              defaultSelectedKey={defaultSelectedKey}
              isMobile
              isClosable
              hideNavigation={() => {
                setIsMobileNavigationActive(!isMobileNavigationActive);
              }}
            />
          </MobileSider>
        </>
      )}
    </AppContext.Consumer>
  );
};

export default ResponsiveNavigation;
