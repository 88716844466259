import React, { Component } from "react";

import "antd/dist/antd.css";

import "./App.css";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import ItemsListPage from "./pages/institutions/ItemsListPage";

import StartCertificatePage from "./pages/certificates/StartCertificatesPage";

import ValidateCertificatePage from "./pages/certificates/ValidateCertificatePage";

import ResponsiveNavigation from "./components/responsive-navigation";

import AppContext from './utils/context/app-context';

const defaultSelectedKeys = {
  "/list/items": "1",
  "/certificates/start": "2",
  "/certificates/validate": "3",
};

/*        <Route path="/list/items" component={ItemsListPage} />

          <Route path="/certificates/start" component={StartCertificatePage} />
          
*/


const defaultSelectedKey = Object.entries(defaultSelectedKeys).find(([key]) =>
  window.location.pathname.includes(key)
);

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {isMobileNavigationActive : false}; 
  }
  render() {
    return (
      <AppContext.Provider value={{...this.state, setIsMobileNavigationActive : (value) => {this.setState({...this.state, isMobileNavigationActive : value })}}}>
      <Router>
        <ResponsiveNavigation defaultSelectedKey={defaultSelectedKey}>
          
        </ResponsiveNavigation>
        <Switch>
          <Route
            path="/certificates/validate"
            component={ValidateCertificatePage}
          />
          <Redirect to="/certificates/validate" />
        </Switch>
      </Router></AppContext.Provider>
    );
  }
}
