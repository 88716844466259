import React, { useState } from "react";

import { Promise } from "bluebird";

import { Layout, Form, Button, Upload, notification } from "antd";

import styled from "styled-components";

import { InboxOutlined, MenuOutlined } from "@ant-design/icons";

import AppContext from "../../utils/context/app-context";

import { useMediaQuery } from "../../utils/hooks";

import { generateFilesHashes, generateFileUrl } from "../../utils/FileHelper";

import PdfViewer from "../../components/pdf-components";

import image from "../../assets/images/cotrugli-logo-bijeli.png";

import axios from 'axios'

const { Header, Content, Footer } = Layout;

const { Dragger } = Upload;

const HeadlineToDisplay = styled.div`
  text-align : center;
  font-size : 20px;
  padding 45px;
`;

const COTRUGLILogo = styled.img`
 z-index : 999;
 height : 60px;
 width 200px;
 position : absolute;
`;

const COTRUGLILogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 2px;
`;

const StartCertificatePage = () => {
  const [fileList, _setFileList] = useState([]);

  const [modalsData, _setModalsData] = useState([]);

  const isMobile = useMediaQuery("(max-width: 600px)");

  const modalsDataRef = React.useRef(modalsData);

  const fileListRef = React.useRef(fileList);

  const setModalsData = (data) => {
    modalsDataRef.current = data;
    _setModalsData(data);
  };

  const setFileList = (data) => {
    fileListRef.current = data;
    _setFileList(data);
  };

  const setIsModalVisible = (key, visible) => {
    setModalsData([
      ...modalsDataRef.current.map((modalData) =>
        modalData.uid === key
          ? { ...modalData, isModalVisible: visible }
          : modalData
      ),
    ]);
  };

  const onConfirm = (key) => {
    setIsModalVisible(key, false);
  };

  const onReject = (key) => {
    setFileList(fileListRef.current.filter(({ uid }) => uid !== key));
    setIsModalVisible(key, false);
  };

  const uploadProps = {
    multiple: true,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);

      setFileList(newFileList);
    },
    beforeUpload: async (_, fs) => {
      setFileList([...fileList, ...fs]);
      setModalsData(
        await Promise.map(fs, async (file) => ({
          fileUrl: await generateFileUrl(file),
          title: file.name,
          isModalVisible: true,
          setIsModalVisible,
          onReject,
          onConfirm,
          uid: file.uid,
        }))
      );
      return false;
    },
    fileList,
  };

  const [loading, setLoading] = useState(false);

  const onFinish = async () => {
    try {
      if (fileList.length === 0) {
        notification.error({
          message: "Error!",
          description: "At least one file should  be uploaded.",
          onClick: () => {
            console.log("Notification Clicked!");
          },
        });
        return;
      }
      setLoading(true);

      const filesHashes = await generateFilesHashes(fileList);


      for (let { fileHash: digitalSignature, fileName, file} of filesHashes) {      

        const bodyFormData = new FormData();
        bodyFormData.append('file', file);
        bodyFormData.append('fileHash', digitalSignature);
        bodyFormData.append('fileName', fileName);
        bodyFormData.append('description', '');

        await axios.post('https://app.cotrugli.tech/api-v2/certificates/upload', bodyFormData, {
          headers: {
            'Content-Type': "multipart/form-data"
          }
        })

        notification.success({
          message: "Success!",
          description: `You have added ${fileName} to your list!`,
          onClick: () => {
            console.log("Notification Clicked!");
          },
        });
      }
      setFileList([]);

      setLoading(false);
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Something went wrong, please try again.",
        onClick: () => {
          console.log("Notification Clicked!");
        },
      });
      setLoading(false);
    }
  };

  return (
    <>
      {modalsData.map(
        ({
          fileUrl,
          title,
          isModalVisible,
          setIsModalVisible,
          uid,
          onReject,
          onConfirm,
        }) => (
          <PdfViewer
            title={title}
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            fileUrl={fileUrl}
            key={uid}
            uid={uid}
            onReject={onReject}
            onConfirm={onConfirm}
            isConfirmable
          />
        )
      )}
      <AppContext.Consumer>
        {({ isMobileNavigationActive, setIsMobileNavigationActive }) => (
          <Layout
            className="site-layout"
            style={{
              marginLeft: isMobile ? "0px" : "200px",
              marginRight: isMobile ? 0 : 20,
              minHeight: isMobile ? "100%" : "98vh",
            }}
          >
            <COTRUGLILogoContainer>
              <COTRUGLILogo src={image} alt="Cotrugli" />
            </COTRUGLILogoContainer>
            <Header
              className="site-layout-background"
              style={{ padding: 0, marginLeft: isMobile ? "0px" : "100px" }}
            >
              {isMobile && (
                <MenuOutlined
                  onClick={() => setIsMobileNavigationActive(true)}
                  style={{
                    color: "white",
                    marginLeft: "20px",
                    fontSize: "25px",
                  }}
                />
              )}
            </Header>
            <HeadlineToDisplay>
              <h1>Choose your document and deploy it to blockchain!</h1>
            </HeadlineToDisplay>

            <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
              <div
                className="site-layout-background"
                style={{
                  padding: 48,
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Form onFinish={onFinish} name="control-hooks">
                  <Dragger {...uploadProps} style={{maxHeight: '200px'}}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                    <p className="ant-upload-hint">
                      Support for a single or bulk upload. Strictly prohibit
                      from uploading company data or other band files
                    </p>
                  </Dragger>
                  <br />
                  <Button
                    size="middle"
                    type="primary"
                    loading={loading}
                    style={{
                      width: "100%",
                    }}
                    htmlType="submit"
                    className="login-form-button"
                  >
                    Click here to upload!
                  </Button>
                </Form>
              </div>
            </Content>
            <Footer style={{ textAlign: "center" }}>
              COTRUGLI ©2020 Created by COTRUGLI Business School
            </Footer>
          </Layout>
        )}
      </AppContext.Consumer>
    </>
  );
};

export default StartCertificatePage;
