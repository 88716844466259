import '@react-pdf-viewer/core/lib/styles/index.css';

import React, { useState } from "react";

import styled from "styled-components";

import {Button} from 'antd';

import { useMediaQuery } from "../../utils/hooks";

import { Worker, Viewer  } from "@react-pdf-viewer/core";

import {CloseCircleOutlined } from "@ant-design/icons";

const ModelOverlay = styled.div`
  position: fixed;
  top : 0;
  bottom : 0;
  left : 0;
  right : 0;
  background-color: #bababa;
  z-index: 99;
  opacity: 0.4;
`;

const ModelCard = styled.div`
  display: flex;
  background-color: #ffff;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
  box-shadow: rgba(39, 55, 96, 0.1) 0px 4px 16px 0px;
  padding: 24px 24px;
  flex-direction : column;
`;

const ModelContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  opacity: 1;
`;

const PdfView = styled(Viewer)`
  width: 100%;
  height: 100%;
`;

const CloseIconButton = styled(CloseCircleOutlined) `
    font-size 25px;
    &:hover {
        opacity: 0.3;
      }
`;

const Header = styled.div`
    height : 80px;
    width : 100%;
    display : flex;
    justify-content : center;
`;

const HeaderLeft = styled.div `
    flex: 1;
`;

const HeaderCenter = styled.div `
    flex: 2;
    text-align : center;
    align-items :center;
`;

const HeaderRight = styled.div `
    flex: 1;
    display : flex;
    justify-content : flex-end;
    align-items : center;
`;

const CloseIconWrapper = styled.div `
    height : 54px;
    width : 50px;
`;

const Footer = styled.div `
   margin-top: auto;
    width : 100%;
    display : flex;
    justify-content : center;
`;

const FooterLeft = styled.div`
      flex : 9;
`;

const FooterRight = styled.div`
      flex : 4;
      justify-content : center;
      align-items : center;
      display : flex;
`;

export default function PdfViewer({title, isModalVisible, setIsModalVisible, fileUrl, uid, onConfirm, onReject, isConfirmable}) {

  const isMobile = useMediaQuery("(max-width: 600px)");

  return isModalVisible && (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
      <ModelContent style={{top:"30px", left : "100px"}}>
        <ModelCard style={{width : isMobile? "100%" : "50%", height : isMobile? "100%" : "80%"}}>
            <Header>
                <HeaderLeft/>
                <HeaderCenter>
                    <h1 style={{fontSize : isMobile ? "23px" : "28px"}}>
                        {title}
                    </h1>
                </HeaderCenter>
                <HeaderRight>
                    <CloseIconWrapper onClick={()=> setIsModalVisible(uid, false)}>
                        <CloseIconButton/>
                    </CloseIconWrapper>
                </HeaderRight>
            </Header>
            <div style={{height : "80%"}}>
        <PdfView fileUrl={fileUrl} />
        </div>
        {isConfirmable && <Footer>
            <FooterLeft/>
            <FooterRight>
            <Button
              size="middle"
              type="danger"
              style={{
                width: "100%",
              }}
              onClick={()=> onReject(uid)}
            >
                Reject
            </Button>
            <div style={{width : "12px"}}></div>
            <Button
              size="middle"
              type="primary"
              style={{
                width: "100%",
              }}
              onClick={()=> onConfirm(uid)}
            >
                Confirm
            </Button>
            </FooterRight>
        </Footer>}
        </ModelCard>
      </ModelContent>
      <ModelOverlay>

      </ModelOverlay>
    </Worker>
  );
}
